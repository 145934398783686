<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <PageHeader :title="headercontent.title" />
        </v-col>

        <v-col md="6">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="form-section"
          >
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="challengeName"
                  outlined
                  label="Challenge Name"
                  class="mr-btm-minus"
                  :rules="challengeRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="numberOfDays"
                  outlined
                  label="Number of days"
                  class="mr-btm-minus"
                  :rules="challengeRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="excerpt"
                  outlined
                  rows="5"
                  label="Challenges Summary"
                :counter="150"
                :maxlength="150"
                @keyup="(e)=>e.target.length < 150"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="description"
                  outlined
                  rows="10"
                  label="Description"
                  :rules="descriptionRules"
                />
              </v-col>
              <v-col cols="12">
                <v-file-input
                  v-model="newImage"
                  outlined
                  class="mt-10"
                  placeholder="Add File"
                  accept="image/*"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="authorName"
                  outlined
                  label="Author Name"
                  class="mr-btm-minus"
                ></v-text-field>
              </v-col>
              <v-col cols="12" v-if="image">
                <div class="imagebox">
                  <img :src="image" alt="" srcset="" />
                  <button @click="removeImage" class="rm"><span class="material-icons">close</span></button>
                </div>
              </v-col>

              <v-col cols="12" class="submitbtn">
                <v-btn
                  :disabled="!valid"
                  class="submitbutton btn-dark"
                  @click="validate"
                  v-if="!loading"
                >
                  {{ headercontent.title }}
                </v-btn>
                <v-progress-circular
                  v-if="loading"
                  indeterminate
                ></v-progress-circular>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col class="questionBox" v-if="this.$route.params.id">
          <h3 class="pb-5">Questions</h3>
          <v-col cols="12">
            <h3 class="mb-5">Add Question</h3>
            <v-text-field
              v-model="question"
              placeholder="Question"
              outlined
            ></v-text-field>
            <v-text-field
              v-model="info"
              placeholder="Info"
              outlined
            ></v-text-field>
            <v-btn
              @click="addQuestion"
              class="mb-5"
              color="green"
              :style="{ color: '#fff' }"
              v-if="!loadingQuestionAdd"
              >Add Question</v-btn
            >
            <v-btn
              @click="addQuestion"
              class="mb-5"
              color="green"
              :style="{ color: '#fff' }"
              v-if="loadingQuestionAdd"
              loading
            ></v-btn>
          </v-col>
          <div v-if="currentChallenge.questions.length == 0">No question added yet</div>
          <draggable v-model="currentChallenge.questions" class="dragBox cursor-pointer" @update="questionListSorted">
            <div
              v-for="(data, index) in currentChallenge.questions"
              :key="index"
              class="questionRepeater"
            >
              <div class="header">
                {{ index + 1 }}
                <v-icon> mdi-sort </v-icon>
              </div>
              <v-text-field
                v-model="data.question"
                placeholder="Question"
                outlined
              ></v-text-field>
              <v-text-field
                v-model="data.info"
                placeholder="Info"
                outlined
              ></v-text-field>
              <div class="d-flex justify-space-between">
                <v-btn
                  v-if="loadingUpdateQuestion && index == updateIndex"
                  loading
                  color="blue"
                  :style="{ color: '#fff' }"
                ></v-btn>
                <v-btn
                  v-if="!loadingUpdateQuestion || index != updateIndex"
                  @click="updateQuestion(index)"
                  color="blue"
                  :style="{ color: '#fff' }"
                  >Update</v-btn
                >
                <v-btn
                  @click="removeQuestion(index)"
                  color="red"
                  :style="{ color: '#fff' }"
                  >Remove</v-btn
                >
              </div>
            </div>
          </draggable>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import PageHeader from "@/components/dashboard/PageHeader";
import { mapActions, mapGetters } from "vuex";
import draggable from "vuedraggable";

export default {
  name: "ChallengeDetail",
  components: { PageHeader, draggable },
  data: () => ({
    valid: true,
    image: null,
    newImage: null,
    challengeName: "",
    description: "",
    sort: 0,
    headercontent: {
      title: "Add Challenge",
      addlink: "/dashboard/emotion/add",
      downloadlink: true,
    },
    content: "<h2>I am Example</h2>",
    challengeRules: [(v) => !!v || "Challenge Title is required"],
    descriptionRules: [(v) => !!v || "Description is required"],
    loading: false,
    numberOfDays: 0,
    question: "",
    info: "",
    loadingQuestionAdd: false,
    loadingUpdateQuestion: false,
    updateIndex: undefined,
    authorName: undefined,
    excerpt: undefined,
  }),
  methods: {
    ...mapActions([
      "createChallenge",
      "findChallenge",
      "postChallengeQuestion",
      "deleteChallengeQuestion",
      "updateChallengeQuestion",
      "applyCurrentQuestionsSort",
      "updateChallenge"
    ]),

    async validate() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const data = {
          challengeName: this.challengeName,
          description: this.description,
          excerpt: this.excerpt,
          numberOfDays: this.numberOfDays,
          authorName: this.authorName,
        };
        if (this.newImage) {
          data.image = this.newImage;
        } else if (!this.image) {
          data.image = null;
        }
        if (this.id) {
          await this.updateChallenge({ id: this.id, data });
        } else {
          await this.createChallenge(data);
        }
        this.loading = false;
      }
    },

    setImage: function (output) {
      this.image = output;
    },

    removeImage() {
      this.image = null;
    },

    async addQuestion() {
      if (this.currentChallenge.questions.length <= this.numberOfDays) {
        this.loadingQuestionAdd = true;
        await this.postChallengeQuestion({
          question: this.question,
          info: this.info,
        });
        this.question = "";
        this.info = "";
        this.loadingQuestionAdd = false;
      } else {
        alert("You can't add more than " + this.numberOfDays + " questions");
      }
    },
    async removeQuestion(index) {
      await this.deleteChallengeQuestion(this.currentChallenge.questions[index]._id);
    },
    async questionListSorted(e) {
      await this.applyCurrentQuestionsSort(e);
    },
    async updateQuestion(index) {
      this.updateIndex = index;
      this.loadingUpdateQuestion = true;
      await this.updateChallengeQuestion({
        id: this.currentChallenge.questions[index]._id,
        data: {
          question: this.currentChallenge.questions[index].question,
          info: this.currentChallenge.questions[index].info,
        },
      });
      this.loadingUpdateQuestion = false;
      this.updateIndex = undefined;
    }
  },
  computed: {
    ...mapGetters(["allLoadingCurd", "currentChallenge"]),
  },

  async created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      await this.findChallenge(this.id);
      this.challengeName = this.currentChallenge.challengeName;
      this.authorName = this.currentChallenge.authorName;
      this.excerpt = this.currentChallenge.excerpt;
      this.description = this.currentChallenge.description;
      this.image = this.currentChallenge.image;
      this.numberOfDays = this.currentChallenge.numberOfDays;
      this.headercontent.title = "Update Challenge";
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.btn-dark {
  background: $tagcolor !important;
  color: $white;
  width: 200px;
  min-height: 50px;
}
.imagebox {
  position: relative;
  width: 200px;

  img {
    width: 100%;
  }

  .rm {
    position: absolute;
    right: 0;
    top: 0;
  }
}
.questionBox {
  max-height: 80vh;
  overflow-y: scroll;
}
.questionRepeater {
  border: 1px solid #efefef;
  padding: 20px 35px;
  position: relative;
  margin-bottom: 10px;
  border-radius: 20px;
  background: #fff;
  cursor: pointer;
}
.indexBox {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  border: 1px solid #ddd;
  border-radius: 30px;
  background: #efefef;
}
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>
